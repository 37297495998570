const itsVariant = process.env.VUE_APP_MODE === 'ITS';
// Attempt to centralize ITS localization. Some legacy code is not convertible and references the useItsLegacy flag
const environment = itsVariant ? {
    endpoint: process.env.VUE_APP_API_ENDPOINT,
    useItsLegacy: true,
    wrongNumberActivityId: 2029, // Production
    // wrongNumberActivityId: 3029, // Staging
    exampleXlsx: "/import_file_example_its.xlsx",
    courseTypes: {
        [-1]: "ITS",
        [-2]: "IFTS",
        [-3]: "UNASSIGNED",
    }
} : {
    endpoint: process.env.VUE_APP_API_ENDPOINT,
    useItsLegacy: false,
    wrongNumberActivityId: 596,
    exampleXlsx: "/import_file_example.xlsx",
    courseTypes: {
        [-1]: "BIENNIAL",
        [-2]: "TRIENNIAL",
        [-3]: "UNASSIGNED",
    }
}
export default environment;
